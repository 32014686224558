//Action types

import { isScrollingDisabled } from '../../ducks/ui.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  createSubscriptionWithStripe,
  getPriceFromStripe,
  getSubscriptionsFromStripe,
} from '../../util/api';
import { storableError } from '../../util/errors';

export const FETCH_SUBSCRIPTION_PRICE_REQUEST =
  'app/StripeSubscriptionPage/FETCH_SUBSCRIPTION_PRICE_REQUEST';
export const FETCH_SUBSCRIPTION_PRICE_SUCCESS =
  'app/StripeSubscriptionPage/FETCH_SUBSCRIPTION_PRICE_SUCCESS';
export const FETCH_SUBSCRIPTION_PRICE_ERROR =
  'app/StripeSubscriptionPage/FETCH_SUBSCRIPTION_PRICE_ERROR';

export const FETCH_SUBSCRIPTIONS_REQUEST = 'app/StripeSubscriptionPage/FETCH_SUBSCRIPTIONS_REQUEST';
export const FETCH_SUBSCRIPTIONS_SUCCESS = 'app/StripeSubscriptionPage/FETCH_SUBSCRIPTIONS_SUCCESS';
export const FETCH_SUBSCRIPTIONS_ERROR = 'app/StripeSubscriptionPage/FETCH_SUBSCRIPTIONS_ERROR';

export const CREATE_SUBSCRIPTIONS_REQUEST =
  'app/StripeSubscriptionPage/CREATE_SUBSCRIPTIONS_REQUEST';
export const CREATE_SUBSCRIPTIONS_SUCCESS =
  'app/StripeSubscriptionPage/CREATE_SUBSCRIPTIONS_SUCCESS';
export const CREATE_SUBSCRIPTIONS_ERROR = 'app/StripeSubscriptionPage/CREATE_SUBSCRIPTIONS_ERROR';

//InitialState
const initialState = {
  subscriptionPlan: null,
  fetchPriceInProgress: false,
  fetchPriceError: null,
  userSubscriptions: [],
  fetchSubscriptionInProgress: false,
  fetchSubscriptionError: null,
  createSubscriptionInProgress: false,
  createSubscriptionError: null,
  subscriptionCreate: [],
};

//Selectors

export const checkScrollingDisabled = state => isScrollingDisabled(state);

//reducer function
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SUBSCRIPTION_PRICE_REQUEST:
      return { ...state, fetchPriceInProgress: true, fetchPriceError: null };
    case FETCH_SUBSCRIPTION_PRICE_SUCCESS:
      return {
        ...state,
        subscriptionPlan: payload,
        fetchPriceInProgress: false,
        fetchPriceError: null,
      };
    case FETCH_SUBSCRIPTION_PRICE_ERROR:
      return {
        ...state,
        fetchPriceInProgress: false,
        fetchPriceError: payload,
      };
    case FETCH_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        fetchSubscriptionInProgress: true,
        fetchSubscriptionError: null,
      };
    case FETCH_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        userSubscriptions: payload,
        fetchSubscriptionInProgress: false,
        fetchSubscriptionError: null,
      };
    case FETCH_SUBSCRIPTIONS_ERROR:
      return { ...state, fetchSubscriptionInProgress: false, fetchSubscriptionError: payload };
    case FETCH_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        fetchSubscriptionInProgress: true,
        fetchSubscriptionError: null,
      };
    case FETCH_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        userSubscriptions: payload,
        fetchSubscriptionInProgress: false,
        fetchSubscriptionError: null,
      };
    case FETCH_SUBSCRIPTIONS_ERROR:
      return { ...state, fetchSubscriptionInProgress: false, fetchSubscriptionError: payload };

    case CREATE_SUBSCRIPTIONS_REQUEST:
      return { ...state, createSubscriptionInProgress: payload, createSubscriptionError: null };
    case CREATE_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptionCreate: payload,
        createSubscriptionInProgress: false,
        createSubscriptionError: null,
      };
    case CREATE_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        createSubscriptionInProgress: false,
        createSubscriptionError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchPriceRequest = () => ({
  type: FETCH_SUBSCRIPTION_PRICE_REQUEST,
});
export const fetchPriceSuccess = data => ({
  type: FETCH_SUBSCRIPTION_PRICE_SUCCESS,
  payload: data,
});

export const fetchPriceError = e => ({
  type: FETCH_SUBSCRIPTION_PRICE_ERROR,
  error: true,
  payload: e,
});

export const fetchSubscriptionsRequest = () => ({
  type: FETCH_SUBSCRIPTIONS_REQUEST,
});
export const fetchSubscriptionsSuccess = data => ({
  type: FETCH_SUBSCRIPTIONS_SUCCESS,
  payload: data,
});

export const fetchSubscriptionsError = e => ({
  type: FETCH_SUBSCRIPTION_PRICE_ERROR,
  error: true,
  payload: e,
});

export const createSubscriptionsRequest = data => ({
  type: CREATE_SUBSCRIPTIONS_REQUEST,
  payload: data,
});
export const createSubscriptionsSuccess = data => ({
  type: CREATE_SUBSCRIPTIONS_SUCCESS,
  payload: data,
});

export const createSubscriptionsError = e => ({
  type: CREATE_SUBSCRIPTIONS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunk ================ //

export const fetchPriceFromStripe = () => async (dispatch, getState, sdk) => {
  dispatch(fetchPriceRequest());

  const response = getPriceFromStripe({})
    .then(response => {
      const filteredResponse = response.filter(plan => plan.unit_amount == 2900);
      const sortedPrice = filteredResponse.sort((planA, planB) => {
        const amountA = planA.unit_amount || 0;
        const amountB = planB.unit_amount || 0;
        return amountA - amountB;
      });
      dispatch(fetchPriceSuccess(sortedPrice));
    })
    .catch(error => {
      dispatch(fetchPriceError(storableError(error)));
    });
};

//Create subscription with Stripe

export const createSubscription = params => async (dispatch, sdk, getState) => {
  dispatch(createSubscriptionsRequest(params.priceID));
  try {
    const subscription = await createSubscriptionWithStripe(params);
    dispatch(createSubscriptionsSuccess(subscription));
    dispatch(fetchCurrentUser());
    return subscription;
  } catch (error) {
    dispatch(createSubscriptionsError(storableError(error)));
  }
};
// Get subscriptions

export const getSubscriptions = params => async (dispatch, sdk, getState) => {
  dispatch(fetchSubscriptionsRequest());
  try {
    const subscriptions = await getSubscriptionsFromStripe(params);
    dispatch(fetchSubscriptionsSuccess(subscriptions));
    return subscriptions;
  } catch (error) {
    dispatch(fetchSubscriptionsError(storableError(error)));
  }
};

//LoadData
export const loadData = params => async (dispatch, getState, sdk) => {
  console.log(111, '&&& &&& => 111');

  try {
    return Promise.all([
      dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] })),
      dispatch(fetchPriceFromStripe()),
    ]);
  } catch (error) {
    dispatch(showListingError(error));
    throw error;
  }
};
